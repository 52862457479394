import { gtm_aam } from './analytics-gtm';
import {getCookieValue} from "@cms/utils/lib/cookie";

function gtag(..._args: unknown[]) {
    window.dataLayer.push(arguments);
}

const getConsentObject = (value: string | null) => {
    const isCookieMarketing = value === '2';
    const isCookieAnalytics = value === '3';
    const isCookieAllAccept = value === '4';

    return {
        'ad_storage': isCookieMarketing || isCookieAllAccept ? 'granted' : 'denied',
        'ad_user_data': isCookieMarketing || isCookieAllAccept ? 'granted' : 'denied',
        'ad_personalization': isCookieMarketing || isCookieAllAccept ? 'granted' : 'denied',
        'analytics_storage': isCookieAnalytics || isCookieAllAccept ? 'granted' : 'denied',
    };
};

const handlerUpdateConsent = (value: string): void => {
    const consentObjectUpdate = getConsentObject(value);
    gtag('consent', 'update', consentObjectUpdate);
};

let analyticsDataLayerConsentCalled = false;

export function analyticsDataLayerConsent() {
    if (!analyticsDataLayerConsentCalled) {
        window.dataLayer = window.dataLayer || [];
        const cookiePolicyGDPRValue = getCookieValue("cookiePolicyGDPR");
        const defaultConsentObject = getConsentObject(cookiePolicyGDPRValue);

        gtag('consent', 'default', defaultConsentObject);

        window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });

        window.addEventListener('onCookiePolicyChange', (event: CustomEvent) => {
            const value = event.detail.cookiePolicyGDPRValue;
            handlerUpdateConsent(value);
        });

        analyticsDataLayerConsentCalled = true;
    }
}

const handlerUpdateLayerYT = (): void => {
    const cookiePolicyGDPRValue = getCookieValue("cookiePolicyGDPR");
    const isCookieAllConsents = cookiePolicyGDPRValue === '4';

    const allContainers = document.querySelectorAll('.box_yt_nocookie');

    allContainers.forEach(container => {
        const iframe = container.querySelector('iframe');
        const iframeTitle = iframe?.getAttribute('title') || '';
        const videoId = iframe?.getAttribute('data-id-yt');
        const overlay = container.querySelector('.youtube-overlay') as HTMLElement;

        if (iframe && isCookieAllConsents) {
            overlay.style.display = 'block';
            overlay.addEventListener('click', (event) => {
                event.preventDefault();
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                                          event: 'pwaVideoYT',
                                          videoID: videoId,
                                          videoTitle: iframeTitle,
                                      });

                const src = iframe.getAttribute('src');
                const newSrc = src.includes('?') ? src + "&autoplay=1" : src + "?autoplay=1";

                iframe.setAttribute('src', newSrc);
                overlay.style.display = 'none';
                iframe.style.pointerEvents = 'auto';
            });

            iframe.style.pointerEvents = 'none';
        } else if (iframe) {
            overlay.style.display = 'none';
            iframe.style.pointerEvents = 'auto';
        }
    });
};


export function analyticsDataLayerYT() {
    handlerUpdateLayerYT();

    window.addEventListener('onCookiePolicyChange', (event: CustomEvent) => {
         handlerUpdateLayerYT();
    });
}

export function analyticsDataLayer() {
    const pathName = document.location.pathname;
    const locationHref = document.location.href;
    const documentTitle = document.title;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'pwaPageView',
        'title': documentTitle,
        'location': locationHref,
        'page': pathName
    });

    if (gtm_aam()) {
        window._gtm_aam_data = gtm_aam();
    }

    window.dataLayer.push({
        'event': 'pwaHeaderView'
    });
}

export function createDataLayerPushTimer () {
    const pushTimeout = 5000;
    let timerId: number = null;
    window.dataLayer = window.dataLayer || [];
    function dataLayerPushTimer () {
        if (timerId !== null) {
            window.clearTimeout(timerId);
        }
        timerId = window.setTimeout(function () {
            window.dataLayer.push({
                'event': 'pwaTimer',
                'pwaTimeOnSite': 5
            });
        }, pushTimeout);
    }
    return dataLayerPushTimer;
}

export function addListenerAnalyticScrollDepth() {
    var storageScrollDepth = '0',
        pageCompletelyScrolled = false;

    function resetStorageScrollDepth() {
        storageScrollDepth = '0';
        pageCompletelyScrolled = false;
    }

    function isThresholdForCurrentDepth(currentScrollDepth: number, scrollThreshold: number) {
        return currentScrollDepth >= scrollThreshold && storageScrollDepth.indexOf(scrollThreshold.toString()) == -1
    }

    function dataLayerPushScrollDepth(percentage: number) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'pwaScrollDepth',
            'pwaScrollThreshold': percentage
        });
        storageScrollDepth += "/" + percentage;
    }

    window.addEventListener('scroll', function () {
        if (pageCompletelyScrolled) return;

        var body = document.body,
            html = document.documentElement,
            windowScrollTop = (window.pageYOffset || html.scrollTop)  - (html.clientTop || 0),
            documentHeight = Math.max( body.scrollHeight, body.offsetHeight, 
                html.clientHeight, html.scrollHeight, html.offsetHeight ),
            windowHeight = window.innerHeight
                || html.clientHeight
                || body.clientHeight,
            currentScrollDepth = Math.round((windowScrollTop / (documentHeight - windowHeight)) * 100);

        switch (true) {
            case isThresholdForCurrentDepth(currentScrollDepth, 25):
                dataLayerPushScrollDepth(25);
                break;
            case isThresholdForCurrentDepth(currentScrollDepth, 50):
                dataLayerPushScrollDepth(50);
                break;
            case isThresholdForCurrentDepth(currentScrollDepth, 75):
                dataLayerPushScrollDepth(75);
                break;
            case isThresholdForCurrentDepth(currentScrollDepth, 100):
                dataLayerPushScrollDepth(100);
                pageCompletelyScrolled = true;
                break;
            default:
                break;
        }
    });

    return resetStorageScrollDepth;
}
